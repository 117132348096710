define(['$'], function($) {
	var mediator = $({});
	var onHandlerRegisteredEvents = $({});
	var mediatorInternalObjectName;
	var delimeter = '~';

	function getMediatorEvents() {
		var mediatorObject = mediator[0];

		if (mediatorInternalObjectName === undefined) {
			for (var item in mediatorObject) {
				if (/jQuery/.test(item) && mediatorObject.hasOwnProperty(item)) {
					mediatorInternalObjectName = item;					
					break;
				}
			}
		}
		
		return mediatorObject[mediatorInternalObjectName] && mediatorObject[mediatorInternalObjectName].events ? 
					mediatorObject[mediatorInternalObjectName].events :
					{};
	}

	function createHandler(callback) {
		return function() {
			var args = Array.prototype.slice.call(arguments, 0);
			callback.apply(this, args);
		}
	}

	function getNewEventName(eventName) {
		return eventName.replace(/\./g, delimeter);
	}

	return {
		addHandler: function(eventName, callback) {
			var eventHandler = createHandler(callback);
			var newEventName = getNewEventName(eventName);
			mediator.on(newEventName, eventHandler);
			onHandlerRegisteredEvents.trigger(newEventName);
			return eventHandler;
		},
		hasHandler: function(eventName) {
			return getMediatorEvents().hasOwnProperty(getNewEventName(eventName));
		},
		publish: function(eventName, data) {
			mediator.trigger(getNewEventName(eventName), data);
		},
		removeHandler: function(eventName, eventHandler) {
			if (eventHandler) {
				mediator.off(getNewEventName(eventName), eventHandler);
			}
		},
		onHandlerRegistered: function(eventName, callback) {
			onHandlerRegisteredEvents.one(getNewEventName(eventName), callback);
		}
	};
});